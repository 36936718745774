<template>
  <section id="custom-attendance-container">
    <AttendanceList
      v-can="'ConfConAten2'"
      title="Consultas personalizadas"
      tooltip="Crie e personalize modelos de consulta com os campos e informações que desejar."
      :button="{
        condition: true,
        text: 'Nova consulta personalizada',
        action: newForm,
        permission: 'ConfConAten4'
      }"
      :forms="consultations"
      :getForms="getForms"
    />

    <AttendanceList
      v-can="'FpPreCon3'"
      class="category"
      title="Pré-consulta personalizada"
      tooltip="Crie e personalize modelo de pré-consulta com os campos e informações que desejar."
      :button="{
        text: 'Nova pré-consulta personalizada',
        condition: !preConsultations.length,
        action: openModal,
        permission: 'ConfConAten5'
      }"
      type="PRE_CONSULTATION"
      :forms="preConsultations"
      :getForms="getForms"
      @edit-pre-consultation="openModal"
    />

    <AttendanceList
      class="category"
      v-can="'ConfConAten3'"
      title="Exames/procedimentos personalizados"
      tooltip="Crie e personalize modelo de exame com os campos e informações que desejar."
      :button="{
        condition: true,
        text: 'Novo exame/procedimento',
        action: newForm,
        permission: 'ConfConAten6'
      }"
      type="EXAM"
      :forms="exams"
      :getForms="getForms"
    />

    <NewAppointmentFormModal
      v-can="'ConfConAten2'"
      :person="person"
      :clinic="clinic"
      :type="type"
      :formsLength="consultations.length"
    />

    <new-pre-consultation-form-modal
      v-can="'ConfConAten5'"
      :person="person"
      :clinic="clinic"
      :customForm="selectedForm"
      @onEdit="getForms"
    />
  </section>
</template>

<script>
export default {
  name: 'my-appointments-tab',
  async created() {
    const isLoading = this.$loading.show()
    try {
      this.consultations = await this.getForms(['ATTENDANCE', 'SURGERY'])
      this.preConsultations = await this.getForms('PRE_CONSULTATION')
      this.exams = await this.getForms('EXAM')
    } catch (error) {
      this.$toast.error(error.message)
    } finally {
      isLoading.hide()
    }
  },
  data: () => ({
    consultations: [],
    preConsultations: [],
    exams: [],
    type: null,
    selectedForm: null
  }),
  props: {
    person: Object,
    clinic: Object
  },
  components: {
    AttendanceList: () => import('@/layouts/Attendance/Config/AttendanceList'),
    NewPreConsultationFormModal: () =>
      import('@/components/Config/Appointment/NewPreConsultationFormModal'),
    NewAppointmentFormModal: () =>
      import('@/components/Config/Appointment/NewAppointmentFormModal')
  },
  methods: {
    openModal(form = null) {
      this.selectedForm = JSON.parse(JSON.stringify(form))
      this.$bvModal.show('new-pre-consultation-form-modal')
    },
    newForm(type) {
      this.type = type
      this.$bvModal.show('new-appointment-form-modal')
    },
    async getForms(type, form) {
      try {
        const { data } = await this.api.getForms(
          this.clinic.id,
          this.person.id,
          type
        )
        if (form) {
          this[form] = data
          return
        }
        return data
      } catch (error) {
        throw new Error('Erro ao carregar atendimentos personalizados')
      }
    }
  }
}
</script>
<style>
#custom-attendance-container {
  .category {
    padding: 1.25rem 0.9rem;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--neutral-200);
  }
  .title-wrapper {
    padding: 0;
  }
}
</style>