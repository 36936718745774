var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"custom-attendance-container"}},[_c('AttendanceList',{directives:[{name:"can",rawName:"v-can",value:('ConfConAten2'),expression:"'ConfConAten2'"}],attrs:{"title":"Consultas personalizadas","tooltip":"Crie e personalize modelos de consulta com os campos e informações que desejar.","button":{
      condition: true,
      text: 'Nova consulta personalizada',
      action: _vm.newForm,
      permission: 'ConfConAten4'
    },"forms":_vm.consultations,"getForms":_vm.getForms}}),_c('AttendanceList',{directives:[{name:"can",rawName:"v-can",value:('FpPreCon3'),expression:"'FpPreCon3'"}],staticClass:"category",attrs:{"title":"Pré-consulta personalizada","tooltip":"Crie e personalize modelo de pré-consulta com os campos e informações que desejar.","button":{
      text: 'Nova pré-consulta personalizada',
      condition: !_vm.preConsultations.length,
      action: _vm.openModal,
      permission: 'ConfConAten5'
    },"type":"PRE_CONSULTATION","forms":_vm.preConsultations,"getForms":_vm.getForms},on:{"edit-pre-consultation":_vm.openModal}}),_c('AttendanceList',{directives:[{name:"can",rawName:"v-can",value:('ConfConAten3'),expression:"'ConfConAten3'"}],staticClass:"category",attrs:{"title":"Exames/procedimentos personalizados","tooltip":"Crie e personalize modelo de exame com os campos e informações que desejar.","button":{
      condition: true,
      text: 'Novo exame/procedimento',
      action: _vm.newForm,
      permission: 'ConfConAten6'
    },"type":"EXAM","forms":_vm.exams,"getForms":_vm.getForms}}),_c('NewAppointmentFormModal',{directives:[{name:"can",rawName:"v-can",value:('ConfConAten2'),expression:"'ConfConAten2'"}],attrs:{"person":_vm.person,"clinic":_vm.clinic,"type":_vm.type,"formsLength":_vm.consultations.length}}),_c('new-pre-consultation-form-modal',{directives:[{name:"can",rawName:"v-can",value:('ConfConAten5'),expression:"'ConfConAten5'"}],attrs:{"person":_vm.person,"clinic":_vm.clinic,"customForm":_vm.selectedForm},on:{"onEdit":_vm.getForms}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }